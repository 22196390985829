// Setup footer
export const setupFooter = () => {
  // social share
  const shareButton = document.querySelector(".share-button");
  if (shareButton instanceof HTMLElement) {
    if (navigator.share) {
      // we can use web share!
      const title = document.getElementsByName("twitter:title")[0].getAttribute("content");
      const text = document.getElementsByName("twitter:description")[0].getAttribute("content");
      
      shareButton.addEventListener("click", event => {
        event.preventDefault();
        navigator.share({
          title: title,
          text: text,
          url: window.location.href
        }).then(() => {
          console.log("Thank you for sharing!");
        })
        .catch(err => {
          console.log(`Couldn"t share because of`, err.message);
        });
      });
    } else {
      // provide a fallback here
      let listItem = shareButton.parentNode;
      if (listItem instanceof HTMLElement) {
        listItem.style.display = "none";
      }
    }
  }

  // language modal
  const languageButton = document.getElementsByClassName("footer__language")[0];
  const closeLanguageButton = document.getElementsByClassName("language-selection__close")[0];
  const modal = document.getElementsByClassName("language-selection")[0];
  const body = document.querySelector("body");
  if (languageButton && modal instanceof HTMLElement) {
    languageButton.addEventListener("click", function() {
      if (modal.classList.contains("active")) {
        modal.classList.remove("active");
        body.style.overflow = "visible";
      } else {
        modal.style.display = "flex";
        setTimeout(() => {
          modal.classList.add("active");
          body.style.overflow = "hidden";
        }, 200);
      }
    });
  }

  if (closeLanguageButton) {
    closeLanguageButton.addEventListener("click", function() {
      const body = document.querySelector("body");
      if (modal.classList.contains("active")) {
        modal.classList.remove("active");
        // menuIcon.classList.remove("active");
        body.style.overflow = "visible";
      }
    });
  }
};

// Import SASS
import "./sass/main.scss";
// Import JavaScript
import { checkDarkMode } from "./js/darkmode";

// Lazy load modules (only if needed)
// const getMenuModule = () => import(/* webpackChunkName: "menu" */ "./js/menu");
// const getFooterModule = () => import(/* webpackChunkName: "footer" */ "./js/footer");

// (function () {
//   checkDarkMode();
//   const hasMenu = document.getElementsByClassName("header")[0];
//   if (hasMenu) {
//     getMenuModule().then(({ setupMenu }) => {
//       setupMenu();
//     }) 
//   }
//   const hasFooter = document.getElementsByClassName("footer")[0];
//   if (hasFooter) {
//     getFooterModule().then(({ setupFooter }) => {
//       setupFooter();
//     }) 
//   }
// })();

import { setupMenu } from "./js/menu";
import { setupFooter } from "./js/footer";

(function () {
  checkDarkMode();
  if (document.getElementsByClassName("header")[0]) {
    setupMenu();
  }
  if (document.getElementsByClassName("footer")[0]) {
    setupFooter();
  }
  if("serviceWorker" in navigator){
    navigator.serviceWorker.register("/sw.js")
      .then(reg => console.log("service worker registered"))
      .catch(err => console.log("service worker not registered", err));
  }
})();

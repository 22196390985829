// Toggle menu
export const setupMenu = () => {
  const menuIcon = document.getElementsByClassName("header__menu-icon")[0];
  if (menuIcon) {
    menuIcon.addEventListener("click", function() {
      const header = document.getElementsByClassName("header")[0];
      const mediaQuery = window.matchMedia("(max-width: 800px)");
      const body = document.querySelector("body");
      if (header.classList.contains("active") && mediaQuery) {
        header.classList.remove("active");
        menuIcon.classList.remove("active");
        body.style.overflow = "visible";
      } else {
        header.classList.add("active");
        menuIcon.classList.add("active");
        body.style.overflow = "hidden";
      }
    });
  }
};

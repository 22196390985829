// Check and enable light / dark mode
export const checkDarkMode = () => {

  const html = document.getElementsByTagName("HTML")[0];
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
  const themeSelected = localStorage.getItem("colorTheme") !== null;
  const theme = localStorage.getItem("colorTheme");

  if ((prefersDark && !themeSelected) || (themeSelected && theme === "dark")) {
    const html = document.getElementsByTagName("HTML")[0];
    html.classList.add("dark");
  }
  // Toggle light / dark mode
  const dataSwitch = document.querySelector("[data-switch-dark]");
  if (dataSwitch) {
    dataSwitch.addEventListener("click", function() {
      const html = document.getElementsByTagName("HTML")[0];
      if (html.classList.contains("dark")) {
        localStorage.setItem("colorTheme", "light");
      } else {
        localStorage.setItem("colorTheme", "dark");
      }
      html.classList.toggle("dark");
    });
  }
};
